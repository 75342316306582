<template>
  <div>
    <div class="p-1 d-flex justify-content-between">
      <div class="text-center">
        <div class="text-muted">
          Subscribed Plan
        </div>
        <div>
          {{ singlePlan.planName }}
          <p-icon v-if="singlePlan.planName" id="planDetails" name="bi-info-circle-fill" color="warning" role="button" />
        </div>
      </div>
      <div class="ml-1 text-center">
        <div class="text-muted">
          Charge Schedule
        </div>
        <div>
          {{ singlePlan.chargeSchedule }}
        </div>
      </div>
      <div class="flex-grow-1"></div>
      <div class="pt-half px-2">
        <dx-util-button v-if="singlePlan.chargeSchedule === 'MONTHLY'" text="Upgrade To Yearly" class="mr-1" type="default" @click="changeToYearly(singlePlan.planId)" />
        <dx-util-button v-if="(userTenantType === TenantTypeEnum.SERVING.value && singlePlan.planName)" text="Change Plan" type="success" class="mr-1" @click="changePlan" />
        <dx-util-button v-if="!singlePlan.planName" text="Enroll Plan" type="success" class="mr-1" @click="changePlan" />
        <dx-util-button v-if="singlePlan.planName" text="Cancel Plan" type="danger" @click="cancelSubscription(singlePlan.planId)" />
      </div>
    </div>
    <div>
        <membership-charges />
    </div>
    <!-- alternative plan list -->
    <membership-plan-list :component-id="membershipPlanListComponentId" :tenant-type="selectedTenantType" />
    <!-- currently subscribed plan information -->
    <dx-util-popover
      v-if="planData"
      :width="420"
      :target="`#planDetails`"
      show-event="dxhoverstart"
      hide-event="dxhoverend"
      position="left"
    >
      <div class="text-center p-half">
        <p-icon :name="planData.iconUrl" />
        <h3 class="my-half">
          {{ planData.name }}
        </h3>
        <div class="mt-half">
          <small>
            {{ planData.description }}
          </small>
          <div class="mt-1">
            <span>Main Benefits</span>
            <ul class="text-left mt-half">
              <li v-for="(item, index) in planData.details" :key="index">
                <div :class="item.is_highlight ? 'text-warning font-weight-bold' : ''">
                  {{ item.feature }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </dx-util-popover>
  </div>
</template>

<script>
import useCurrentUser from '@/libs/app/current-user'
import { ref, onMounted } from '@vue/composition-api'
import membershipSubscriptionService from '@/http/requests/membership/subscription'
import userManagementService from '@/http/requests/system/userManagementService'
import { v4 as uuidv4 } from 'uuid'
import { TenantTypeEnum } from '@/enums'
import membershipPlanService from '@/http/requests/membership/plan'

export default {
  components: {
    'membership-plan-list': () => import('@/views/apps/membership/components/MembershipPlanList.vue'),
    'membership-charges': () => import('@/views/apps/membership/membership-charges/MembershipCharges.vue'),
  },
  setup() {
    const { subscribedMembershipPlans, userTenantType } = useCurrentUser()
    const singlePlan = subscribedMembershipPlans?.length > 0 ? subscribedMembershipPlans[0] : 'N/A'

    const planData = ref({})
    onMounted(async () => {
      const result = await membershipPlanService.getPlanPackages(userTenantType.value)
      planData.value = result.data.body.filter(el => el.id.toString() === singlePlan?.planId)[0]
    })
    return {
      singlePlan,
      planData,
      userTenantType,
    }
  },
  data() {
    return {
      TenantTypeEnum,
      selectedTenantType: 'SERVING',
      membershipPlanListComponentId: '',
    }
  },
  methods: {
    changeToYearly(planId) {
      this.$swal({
        title: 'Are you sure you want to change this plan subscription to yearly?',
        text: 'Charge schedule change will be effective starting from the next month.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it to yearly',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((async result => {
        if (result.value) {
          const res = await membershipSubscriptionService.changeToYearly(planId)
          if (res) {
            this.$swal({
              icon: 'success',
              title: 'Enrolled!',
              text: 'You have enrolled yearly subscription.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          await userManagementService.refreshAuthData()
          window.location.reload()
        }
      }))
    },
    changePlan() {
      this.selectedTenantType = this.userTenantType
      this.membershipPlanListComponentId = uuidv4()
    },
    cancelSubscription(planId) {
      const cancellationMessage = this.userTenantType === 'INDIVIDUAL' ? 'Membership cancellation is effective for your next billing period.'
        : 'If you want to change your existing plan, please use green "Change Plan" button. Membership cancellation is effective for your next billing period.'
      this.$swal({
        title: 'Are you sure you want to cancel this plan subscription?',
        text: cancellationMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete subscription',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((async result => {
        if (result.value) {
          const res = await membershipSubscriptionService.cancel(planId)
          if (res) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Subscription has been cancelled.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          await userManagementService.refreshAuthData()
          window.location.reload()
        }
      }))
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
